export function list(params = null) {
    return axios({
        url: `/api/articles`,
        method: 'get',
        params: params,
    });
}

export function show(slug) {
    return axios({
        url: `/api/articles/show`,
        method: 'get',
        params: {
            slug: slug
        }
    });
}
